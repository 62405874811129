/** @jsxImportSource @emotion/react */
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MapContainerStyle, MapWrapperStyle } from './Map.css';
import HexManager from './hexManager';
import { ExplorerContext } from '../ExplorerContext';
import { useNavigate } from 'react-router-dom';
import { cellToLatLng } from 'h3-js';

mapboxgl.accessToken = 'pk.eyJ1Ijoia25lZWNsYXNzIiwiYSI6ImNrNjF1eXpxMTA2eGEzbW80ajR1YXkxanoifQ.OM6Lk88DYcpIiyp95kFLag';

export const Map = () => {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(18.587822);
    const [lat] = useState(50.5215021);
    const [zoom] = useState(4);
    const [mapInitDone, setMapInitDone] = useState(false);
    const [hexManager, setHexManager] = useState()
    const { hexes, openInfoBar, currentHex, setMapLoaded } = useContext(ExplorerContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (map.current) return; // initialize map only once
        const latestLocationData = localStorage.getItem("latestLocation")
        const latestLocation = latestLocationData ? JSON.parse(latestLocationData) : null
        const center = latestLocation ? [latestLocation.lng, latestLocation.lat] : [lng, lat]
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/kneeclass/clc0aqjg3000614ry1gegeeog',
            center: center,
            zoom: latestLocation ? latestLocation.zoom : zoom,
            minZoom: 2.7,
            maxPitch: 0,
            dragRotate: false
            
        });
        map.current.on('load', () => {
            setMapInitDone(true)
        })
        map.current.on('moveend', (e) => {
            localStorage.setItem("latestLocation", JSON.stringify({...e.target.getCenter(),...{zoom:e.target.getZoom()}}))
        });
    });


    const hexClick = useCallback((hex) => {
        navigate(`/hex/${hex}`)
        openInfoBar(true)
    }, [navigate, openInfoBar])

    useEffect(() => {
        if (hexes.length === 0) return
        if(!mapInitDone) return
        const hexMgr = new HexManager(map.current, "h3-hexes", hexClick);
        setHexManager(hexMgr)
        hexMgr.addHexes(hexes)
        setMapLoaded(true)
    }, [hexes,mapInitDone]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!hexManager || !currentHex) return
        hexManager.setCurrent(currentHex.index)
        if (currentHex.focus) {
            const hexCoords = cellToLatLng(currentHex.index)
            hexManager.focusHexLocation(hexCoords[1], hexCoords[0])
        }
    }, [currentHex, hexManager])

    return <div css={MapWrapperStyle}>
        <div ref={mapContainer} css={MapContainerStyle} />
    </div>

}