
import SunCalc from 'suncalc'
import {cellToBoundary, cellToLatLng} from 'h3-js';




class HexManager{
    constructor(map, sourceId, onHexClick) {
        this.map = map;
        this.sourceId = sourceId;
        this.onHexClick = onHexClick;
        this.hoveredStateId = null;
        this.initialized = false;
        this.geoJson = null
    }
    
    #calDayLight = (hex) =>{
        const center = cellToLatLng(hex)
        const now = new Date()
        const times = SunCalc.getTimes(now, center[0], center[1]);
        return (now > new Date(times.sunrise) && now < new Date(times.sunset)).toString()
    }

    #hexsToGeoJSON = (hexagons) => {
        const features = hexagons.map((hex, i) => {
            const vertices = cellToBoundary(hex, true);
            return {
                type: 'Feature',
                properties: {
                    hex: hex,
                    daylight: this.#calDayLight(hex),
                    current: false.toString()
                },
                id: i,
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        [...vertices]
                    ]
                }
            }
        })
        const geojson = {
            "type": "FeatureCollection",
            "features": features,
        }
    
        return geojson;
    }

    #addEvents(layerId) {
        this.map.on('mousemove', layerId, (e) => {
            if (e.features.length > 0) {
                if (this.hoveredStateId !== null) {
                    this.map.setFeatureState(
                        { source: this.sourceId, id: this.hoveredStateId },
                        { hover: false }
                    );
                }
                this.hoveredStateId = e.features[0].id;
                this.map.setFeatureState(
                    { source: this.sourceId, id: this.hoveredStateId },
                    { hover: true }
                );
            }
            this.map.getCanvas().style.cursor = 'pointer'
        });
        this.map.on('mouseleave', layerId, () => {
            if (this.hoveredStateId !== null) {
                this.map.setFeatureState(
                    { source: this.sourceId, id: this.hoveredStateId },
                    { hover: false }
                );
            }
            this.hoveredStateId = null;
            this.map.getCanvas().style.cursor = ''
        });

        this.map.on('click', layerId, (e) => {
            this.onHexClick(e.features[0].properties.hex)
        });
    }

    #startSunTerminator() {
        setInterval(() => {
            this.geoJson.features.forEach(f =>  f.properties.daylight = this.#calDayLight(f.properties.hex));
            this.map.getSource(this.sourceId).setData(this.geoJson);
        }, 60000)
    }

    get isInitialized() {
        return this.initialized
    }

    setCurrent = (h3Index) => {

        this.geoJson.features.forEach(f => f.properties.current = false.toString());
        if (h3Index) {
            const hex = h3Index.toLowerCase();
            const current = this.geoJson.features.find(f => f.properties.hex === hex);
            if (!current) return;
            current.properties.current = true.toString()
        }
        this.map.getSource(this.sourceId).setData(this.geoJson);
    }

    focusHexLocation = (lng, lat) => {
        this.map.flyTo({
            center: [lng, lat],
            zoom: 9
        })
    }

    addHexes = (hexes) => {
        this.initialized = true
        const layerId = `${this.sourceId}-layer`;
        this.geoJson = this.#hexsToGeoJSON(hexes)
        let source = this.map.getSource(this.sourceId);
        if (!source) {
            this.map.addSource(this.sourceId, {
                type: 'geojson',
                data: this.geoJson,
                tolerance: 0.00001
            });
            this.map.addLayer({
              id: layerId,
              source: this.sourceId,
              type: 'fill',
              interactive: true,
              paint: {
                'fill-outline-color': 'rgba(0,0,0,0.5)',
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    1,
                    0.6
                  ],
                'fill-color': [
                    "match",
                    ["get", "current"],
                        "true",
                            "#ff1277",
                        "false",
                            ["match",
                                ['get', 'daylight'],
                                "true",
                                    '#FED700',
                                "false",
                                    '#969a91',
                                '#fff'
                            ],
                        '#fff'
                ]
              }
            });
            this.map.addLayer({
                id: "outline",
                source: this.sourceId,
                type: 'line',
                'layout': {
                },
                'paint': {
                    'line-opacity': 0.6,
                    'line-color': [
                        "match",
                        ["get", "current"],
                            "true",
                                "#ff1277",
                            "false",
                                ["match",
                                    ['get', 'daylight'],
                                    "true",
                                        '#FED700',
                                    "false",
                                        '#969a91',
                                    '#fff'
                                ],
                            '#fff'
                    ],
                    'line-width': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        5, 4,
                        10, 0
                    ],
                    // 'line-blur': [
                    //     'interpolate',
                    //     ['linear'],
                    //     ['zoom'],
                    //     4, 5,
                    //     7, 0
                    // ]
                }
            });

            this.map.addLayer({
                id: 'trees-heat',
                type: 'heatmap',
                source: this.sourceId,
                maxzoom: 15,
                paint: {
                // increase intensity as zoom level increases
                'heatmap-intensity': {
                    stops: [
                    [3, 0.4],
                    [5, 0.3],
                    [7, 0]
                    ]
                },
                // assign color values be applied to points depending on their density
                'heatmap-color': [
                    'interpolate',
                    ['linear'],
                    ['heatmap-density'],
                    0,
                    'rgba(0,0,0,0)',
                    5,
                    'rgba(254, 215, 0, 1)'
                ],
                // increase radius as zoom increases
                'heatmap-radius': {
                    stops: [
                    [11, 15],
                    [15, 20]
                    ]
                },
                // decrease opacity to transition into the circle layer
                'heatmap-opacity': {
                    default: 1,
                    stops: [
                    [11, 0.8],
                    [15, 0]
                    ]
                }
                }
            },
            'waterway-label'
            );

            this.#startSunTerminator()
            this.#addEvents(layerId);
        }
    
    }

}

export default HexManager