/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InfoSectionListStyle, InfoSectionStyle, InfoSectionHeadingStyle } from "../BarStyle.css";
import { ExplorerContext } from "../ExplorerContext";



const GatewaySelector = () => {

    const { hex } = useParams();
    const [data, setData] = useState();
    const {loadHexGateways, openInfoBar, setCurrentHex, currentHex} = useContext(ExplorerContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (!hex) return
        setData(null)
        setCurrentHex({index: hex, focus: !currentHex})
        loadHexGateways(hex, (d) => {
            const gws = d.data.gateway.gateways
            if(gws.length === 1){
                navigate(`/egw/${gws[0].id}`)
            }else{
                setData(gws)
                openInfoBar(true)
            }
        })
        
    },[hex]) // eslint-disable-line react-hooks/exhaustive-deps


    return <div>
        <div css={[InfoSectionStyle, InfoSectionHeadingStyle]}>                
                <h2>Hex: {hex}</h2>
        </div>
        <ul css={InfoSectionListStyle}>
            {data ? data.map((egw) => {
                return <li key={egw.id}>
                    <Link to={`/egw/${egw.id}`}>
                        <h2>{egw.name}</h2>
                        <span>{egw.id}</span>
                    </Link>
                </li>
            }) : <SkeletonTheme baseColor="#374051" highlightColor="rgba(106, 134, 186,0.2)"> <Skeleton height={68} count={2} /> </SkeletonTheme>}
        </ul>
    </div>
}


export default GatewaySelector