/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";




export const BlockInformationStyle = css`

    display: flex;
    justify-content: space-between;

    span{
        display: flex;
        align-items: center;
    }

    svg{
        width:21px;
        margin-right: 4px;
    }

`

export const BlockDataInfoListStyle = css`

    display: flex;
    flex-wrap: wrap;
    li{
        text-align:left;
        box-sizing: border-box;
        padding: 7px;
        width: 50%;
        p{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 10%;
            font-family: "Gilroy Light";
            font-size: 15px;
        }
        h4{

            font-size: 18px;
            margin-bottom: 5px;
        }
        
    }

`

export const BlockTransactionsListStyle = css`

    margin-top: 15px;

    li{
        text-align:left;
        margin-bottom: 25px;
        button{
            background: none;
            color: #fff;
            border: none;
            font-family: "Gilroy Light";
            font-size: 18px;
            cursor: pointer;
            text-shadow: #000 0px 0 3px;
            text-decoration: underline;
            &:hover{
                color: rgba(255,255,255,0.8)
            }

        }
        .blockdata{
            margin-top: 4px;
            text-align:left;
            color: #fff;
            padding: 10px;
            background: rgba(255,255,255,0.1);

            border-radius: 2px;
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            div{
                width: 50%;
                h4{
                    font-size: 18px;
                    margin-bottom: 5px;
                }
                p{
                    padding-left: 10%;
                    font-family: "Gilroy Light";
                    font-size: 15px;
                }
            }
        }
    }

`