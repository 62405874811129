/** @jsxImportSource @emotion/react */
import { useContext, useEffect } from "react"
import { CloseInfoTabStyle, InfoBarWrapperStyle } from "../BarStyle.css"
import 'react-loading-skeleton/dist/skeleton.css'
import { ExplorerContext } from "../ExplorerContext"
import { useMatch, useNavigate, useOutlet } from "react-router-dom";
import { UilAngleLeft } from '@iconscout/react-unicons'
import { css } from "@emotion/react";




export const InfoBar = () => {

    const { openInfoBar, forcedInfoBarContent, setForcedInfoBarContent,closeInfoBar, setCurrentHex} = useContext(ExplorerContext)
    const outlet = useOutlet();
    const egwRoute  = useMatch("/egw/:id")
    const hexRoute = useMatch("/hex/:hex")
    const navigate = useNavigate()
    //ugly hack
    let activeRoute = outlet != null && (egwRoute || hexRoute)


    useEffect(() => {
        setForcedInfoBarContent(null)
    },[egwRoute,hexRoute]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (egwRoute || hexRoute)
            openInfoBar(true)
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClose = () => {
        if (forcedInfoBarContent)
            setForcedInfoBarContent(null)
        else {
            closeInfoBar(false)
            setCurrentHex({hex: null, egw: null})
            navigate("/")
        }
        
    }

    return <div css={[InfoBarWrapperStyle(activeRoute || forcedInfoBarContent),css`z-index:2;`]}>    
        {(activeRoute || forcedInfoBarContent) && <button onClick={onClose} css={CloseInfoTabStyle()}><UilAngleLeft /></button>}
        <div className="innerScroll">
            {activeRoute && outlet}
            {!activeRoute && forcedInfoBarContent}
        </div>

    </div>

}