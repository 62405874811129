/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'


export const MapContainerStyle = css`

    width: 100vw;
    height: 100vh;

`

export const MapWrapperStyle = css`
    height: 100vh;
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    width: 100vw;
    background: #000;
    position: absolute;
    top: 0px;
    left: 0px;
`