/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { medium, large } from '../Global.css'


export const HeaderContainerStyle = (open) => css`
    padding: 2px 5px;
    z-index: 2;
    background-color: rgba(29, 29, 29,0.9);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    ${open ? `
        border-image: linear-gradient(90deg, transparent 0%, transparent 90%, rgba(254, 215, 0, 0.5) 90%) 1;
        ${medium}{
            border-image: linear-gradient(90deg, transparent 0%, transparent 50%, rgba(254, 215, 0, 0.5) 50%) 1;
        }
        ${large}{
            border-image: linear-gradient(90deg, transparent 0%, transparent 476px, rgba(254, 215, 0, 0.5) 476px) 1;
        }` : 
        "border-bottom-color: rgba(254, 215, 0, 0.5);"}
    padding: 9px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    pointer-events: none;
    box-sizing: border-box;
    width: 100%;
    gap: 8px;
    overflow: hidden;
    

    ${medium}{
        padding: 8px 18px;
        gap: 20px;
    }

    a{
        text-decoration: none;
        font-family: "Gilroy Light";
        font-size: 15px;
        ${medium}{
            font-size: 20px;
        }
        
        pointer-events: auto;
        &:hover:not(.logo){
            color: rgba(254, 215, 0, 1);
        }
    }

    .logo{
        font-size: 20px;
        ${medium}{
            font-size: 30px;
        }
        line-height: 30px;
        font-weight: normal;


        h1{
            display: flex;
            color: #fff;
            align-items: center;
            font-family: "Gilroy Light";
            font-weight: 500;
            > span{
                margin-left: 5px;
                span{
                    position: relative;
                    bottom: 2px;
                    font-size: 20px;
                    color: rgba(255,255,255,0.8)   
                }
            }
            svg{
                width: 50px;
                height: 50px;
                ${medium}{
                    width: 70px;
                    height: 70px;
                }
                fill: rgba(254, 215, 0, 1);
            }
        }
    }

    a:nth-child(2){
        margin-left: auto;
    }


`