/** @jsxImportSource @emotion/react */
import { useContext } from "react"
import { HeaderContainerStyle } from "./Header.css"
import { ExplorerContext } from "../ExplorerContext"


export const Header = () => {

    const {infoBarOpen} = useContext(ExplorerContext)
    return <div css={HeaderContainerStyle(infoBarOpen)}>
      <a href="/" className="logo">
        <h1>
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><g><path d="M92.634,74.921c-18.805-13.074-30.901-20.278-7.622-15.287c-22.56-7.622-8.479-7.708,14.363-9.634   c-22.842-1.926-36.923-2.012-14.363-9.633c-23.279,4.991-11.183-2.213,7.622-15.287c-20.623,9.972-32.824,16.985-16.952-0.76   c-17.745,15.871-10.733,3.668-0.761-16.954C61.847,26.17,54.643,38.267,59.635,14.988C52.012,37.547,51.927,23.467,50,0.625   c-1.926,22.842-2.012,36.922-9.633,14.363c4.991,23.278-2.213,11.183-15.287-7.623c9.972,20.622,16.985,32.825-0.76,16.954   c15.871,17.745,3.668,10.732-16.954,0.76c18.805,13.074,30.901,20.278,7.623,15.287C37.547,47.988,23.467,48.074,0.625,50   c22.842,1.926,36.922,2.012,14.363,9.634c23.278-4.991,11.182,2.213-7.623,15.287c20.622-9.973,32.825-16.984,16.954,0.761   c17.745-15.872,10.732-3.671,0.76,16.952c13.074-18.805,20.278-30.901,15.287-7.622c7.622-22.56,7.707-8.479,9.633,14.363   c1.927-22.842,2.012-36.923,9.635-14.363c-4.992-23.279,2.212-11.183,15.286,7.622c-9.973-20.62-16.984-32.824,0.761-16.952   C59.81,57.937,72.014,64.948,92.634,74.921z M50,66c-8.836,0-16-7.163-16-16c0-8.836,7.164-16,16-16c8.837,0,16,7.164,16,16   C66,58.837,58.837,66,50,66z"></path></g></svg>
          <span>SRCFUL</span>
        </h1>
      </a>
      <a href="https://store.rakwireless.com/products/srcful-energy-gateway">Shop</a>
      <a href="https://docs.srcful.io/">Docs</a>
      <a href="https://www.youtube.com/@srcful-official">YouTube</a>
      <a href="https://discord.gg/srcful">Discord</a>
    </div>

}