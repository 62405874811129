/** @jsxImportSource @emotion/react */
import { CloseInfoTabStyle, InfoBarWrapperStyle } from "../BarStyle.css"
import { UilAngleRight } from '@iconscout/react-unicons'
import { ExplorerContext } from "../ExplorerContext"
import { useContext } from "react"
import { useMatch, useOutlet, useNavigate } from "react-router-dom"

export const DataBar = () => {

    const {closeDataBar, dataBarOpen} = useContext(ExplorerContext)

    const outlet = useOutlet();
    const navigate = useNavigate()
    const blocksRoute = useMatch("/blocks")
    const blockRoute  = useMatch("/blocks/:block")
    const activeRoute = outlet != null && (blocksRoute || blockRoute) && dataBarOpen


    return <div css={InfoBarWrapperStyle(activeRoute, "right")} className={activeRoute && "open"}>
        {activeRoute && <button onClick={() => {
            navigate("/")
            closeDataBar(false)
        }} css={CloseInfoTabStyle("right")}>
            <UilAngleRight />
        </button>}
       <div className="innerScroll">
            {activeRoute && outlet}
        </div>
    </div>

}