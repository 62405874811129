
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
    BarElement
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
    BarElement
  );

//as chartjs js will not print null we need to give it a value that we later can identify as null and paint red... we use 0.001337
//as only only two fractions are printed we can use 0.001337 as a value that will not be printed.
//im very sorry about this hack... it is what it is.
const numberOfDeath = 0.001337;

export const options = (tooltipFormat,startTime) => { return {
    responsive: true,
    cubicInterpolationMode: "monotone",
    plugins: {
        legend: {
            labels: {
                color: 'white',
                boxWidth: 0,
            }
        },
        tooltip:{
            callbacks:{
                label: function(context) {
                    if(context.raw === numberOfDeath) return "No data";
                    return context.formattedValue + " " + context.dataset.label;
                },
                labelColor: function(context) {
                    if(context.raw === numberOfDeath) return { borderColor: "transparent", backgroundColor: "red" };
                    return { borderColor: context.dataset.pointBorderColor, backgroundColor: context.dataset.pointBackgroundColor };
                }
            }
        }
    },
    scales: {
        x: {
            type: 'time',
            min: startTime,
            max: new Date(),
            time: {
                tooltipFormat: tooltipFormat,
                displayFormats: { hour: 'HH:mm' },
            },
            beginAtZero: true,
            ticks:{
                color: "#FAF9F6",
            },
            grid: {
                display: false
            },
            border: {
                color: "transparent"
            }
        },
        y: {
            ticks:{
                color: "#FAF9F6",
                count: 5,
                format: { maximumFractionDigits: 2, minimumFractionDigits: 0 }
            },
            min: 0,
            border: {
                color: "black"
            }
        }
    }
  }};



const ProductionGraph = ({data, type = "line", tooltipFormat="yyyy-MM-dd HH:mm", label = "kW", startTime}) => {
    
    if(!data) return null;

    const labels = data.map(x => x.when);
    const dataset = data.map(x => {
        if(x.power === null) return numberOfDeath;
        if(type === "line") return x.power/1000
        return x.power
    });;

    const ChartType = type === "line" ? Line : Bar;

    return <ChartType options={options(tooltipFormat,startTime)} data={{
        labels: labels,
        datasets: [{
            label: label,
            data: dataset,
            spanGaps: false,
            fill:true,
            borderColor: 'rgba(173, 144, 36, 0.8)',
            pointBackgroundColor: 'rgba(173, 144, 36, 1)',
            pointBorderColor: 'transparent',
            backgroundColor: 'rgba(173, 144, 36, 0.5)',
            pointRadius: 4,
            pointStyle: false,
            segment: {
                borderColor: ctx => {
                    if(ctx.p0.raw === numberOfDeath && ctx.p1.raw === numberOfDeath) return "rgba(255,0,0,0.5)";
                    return "rgba(173, 144, 36, 0.8)";
                }
            }
        }]
    }}  />

}



export default ProductionGraph;