/** @jsxImportSource @emotion/react */
import { Link, useParams } from "react-router-dom"
import { InfoSectionStyle, InfoSectionHeadingStyle, InfoSectionHeadingStyleBack } from "../BarStyle.css"
import { UilAngleLeftB } from '@iconscout/react-unicons'
import { useQuery } from "@tanstack/react-query"
import { ExplorerContext } from "../ExplorerContext"
import { useContext } from "react"
import { BlockDataInfoListStyle, BlockTransactionsListStyle } from "./BlocksContent.css"
import { format } from "date-fns"
import { css } from "@emotion/react"


const BlockContent = () => {

    const { block } = useParams()
    const { loadBlock, bulkQueryNames, focusAndFindGw } = useContext(ExplorerContext)

    const { data, isLoading } = useQuery({
        queryKey: [block+"-block"],
        queryFn: async () => {
            var blockResponse = await loadBlock(block)
            return blockResponse?.data?.blocks?.block
        }
    })

    const bulkLoadNames = useQuery({
        queryKey: [block+"-names"],
        queryFn: async () => {
            if(!data) return
            const serials = data.transactions.map(x => x.serial)
            return await bulkQueryNames(serials)
        },
        enabled: !!data
    })

    return <div>
        <div css={[InfoSectionStyle, InfoSectionHeadingStyle]}>
            <h2>{parseInt(block).toLocaleString()}</h2>
            <span className="id">2023-03-16T08:13:01</span>
            <Link css={InfoSectionHeadingStyleBack} to={`/blocks`}><UilAngleLeftB /></Link>
        </div>
        <div css={InfoSectionStyle}>
            <h3>Block data</h3>
            {!isLoading && data ?
                <ul css={BlockDataInfoListStyle}>
                    <li><h4>Start</h4> <p>{format(new Date(data.startTimestamp), "yyyy-MM-dd HH:mm")}</p></li>
                    <li><h4>End</h4> <p>{format(new Date(data.endTimestamp), "yyyy-MM-dd HH:mm")}</p></li>
                    <li><h4>Data hash</h4> <p>{data.hashData}</p></li>
                    <li><h4>Prev block hash</h4> <p>{data.hashPrevBlock}</p></li>
                    <li><h4>Production</h4> <p>{Math.floor(data.data.pop.totalProduction)} kW</p></li>
                    <li><h4>DataPoints</h4> <p>{data.data.pop.totalDataPoints}</p></li>
                    <li><h4>Total SRC Distribution</h4> <p>{data.data.totalSrcDistribution} SRC</p></li>
                </ul>
                :
                null}

        </div>
        <div css={[InfoSectionStyle, css`flex:1;`]}>
            <h3>Transactions</h3>
            {!isLoading && data ?
                <ul css={BlockTransactionsListStyle}>
                    {data.transactions.map(t => {
                        return <li>
                            {!bulkLoadNames.isLoading && t.serial !== 'srcful' &&
                                <button onClick={() => focusAndFindGw(t.serial)}>
                                    {bulkLoadNames.data[t.serial]}
                                </button>
                            }
                            <div className="blockdata">
                                <div><h4>Serial</h4><p>{t.serial}</p></div>
                                <div><h4>kW</h4><p>{t.rewardTypes[0].power && parseFloat(t.rewardTypes[0].power).toFixed(2)}</p></div>
                                <div><h4>SRC</h4><p>{parseFloat(t.rewardTypes[0].srcAllocation).toFixed(9)}</p></div>
                                <div><h4>Data points</h4><p>{t.rewardTypes[0].dataPoints}</p></div>
                            </div>
                        </li>
                    })}
                </ul> : null}
        </div>
    </div>

}

export default BlockContent