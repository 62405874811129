/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useContext, useState } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Link, useParams } from "react-router-dom"
import { ExplorerContext } from "../ExplorerContext"
import { ProductionBoxStyles, ContentFooterLinks,ScopesButtonGroupStyle } from "./InfobarContent.css"
import { UilAngleLeftB } from '@iconscout/react-unicons'
import { InfoSectionHeadingStyle, InfoSectionStyle, InfoSectionHeadingStyleBack } from "../BarStyle.css"
import ProductionGraph from "../ProductionGraph/ProductionGraph"
import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { formatISO } from "date-fns"
import { Tooltip } from "react-tooltip"


const minutesDiff = (dateTimeValue2, dateTimeValue1) => {
    var differenceValue =(dateTimeValue2.getTime() - dateTimeValue1.getTime()) / 1000;
    differenceValue /= 60;
    return Math.abs(Math.round(differenceValue));
}

const numberOfMsInADay = 86400000

const scopes = [
    {
        startDateFn:() => {
            const date = formatISO(new Date(new Date() - (30 * numberOfMsInADay)), { representation: 'date' })
            return date
        },
        key: "30d",
        displayName: "30 days",
        chartType: "Bar",
        tooltipFormat: "yyyy-MM-dd",
        label: "kWh"
    },
    {
        startDateFn:() => {
            return formatISO(new Date(new Date() - (7*numberOfMsInADay)))
        },
        key: "7d",
        displayName: "7 days",
        resolution: "1h"
    },
    {
        startDateFn:() => {
            return formatISO(new Date(new Date() - (1*numberOfMsInADay)))
        },
        displayName: "24 hours",
        key: "24h",
        resolution: "10m"
    }
]

export const InfobarContent = ({serial}) => {
    
    const { gwId } = useParams();
    const { loadHistogramProductionData, loadDailykWhProductionData, setCurrentHex, mapLoaded, loadGateway } = useContext(ExplorerContext)
    const [activeScope, setActiveScope] = useState("24h")

    const gwSerial = gwId??serial
    const currencyFormater = Intl.NumberFormat("en-US", {maximumFractionDigits: 0});
    const isOnline = () => {
        if (!data) return false;
        if (!data.proofOfSource?.latest) return false;
        return minutesDiff(new Date(data.proofOfSource.latest.when), new Date()) < 10;
    }

    const selectedScope = scopes.find(x => x.key === activeScope);
    const gatewayRequest = useQuery({
        queryKey: ["gw-" + gwSerial],
        queryFn: async () => {
            var gwResponse = await loadGateway(gwSerial);

            return gwResponse;
        }
    })
    const {data, isRefetching} =  useQuery({
        queryKey: ["proddata",gwSerial,activeScope],
        enabled: gatewayRequest.isSuccess,
        queryFn: async () => {
            const tz = gatewayRequest.data.timeZone
            if (selectedScope.key === "30d") {
                return await loadDailykWhProductionData(gwSerial, selectedScope.startDateFn(), tz)
            }
            else {
                return await loadHistogramProductionData(gwSerial, selectedScope.startDateFn(), selectedScope.resolution, tz)
            }
        },
        refetchInterval: 10000
    })

    //zoom to selected hex
    useEffect(() => {
        setTimeout(() => {
            if (gatewayRequest.data && gatewayRequest.data.h3Index) {            
                setCurrentHex({
                    index: gatewayRequest.data.h3Index,
                    focus: true
                })
            }
        },0)
    },[mapLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

    const skeletonMargin = (bottom) => { return {"marginBottom":bottom}}

    return <SkeletonTheme baseColor="#202939" highlightColor="rgba(106, 134, 186,0.2)">
        <div>
            <div css={[InfoSectionStyle, InfoSectionHeadingStyle]}>                
                <h2>{gatewayRequest.data ? gatewayRequest.data.name ?? "Unknown Gateway 😭" : <Skeleton count={1} style={skeletonMargin("0px")} width="60%" />}</h2>
                <span className="id">{gatewayRequest.data ? gatewayRequest.data.id ?? "" : <Skeleton style={skeletonMargin("0px")} count={1} width="100px" />}</span>
                {gatewayRequest.data && <Link css={InfoSectionHeadingStyleBack} to={`/hex/${gatewayRequest.data.h3Index}`}><UilAngleLeftB /></Link>}
            </div>
          

        <div css={[InfoSectionStyle]}>
                <h3>Production</h3>

                <div css={ProductionBoxStyles}>
                    <div>
                        <Tooltip id="currentPower" place={"top-start"} noArrow content="The latest saved power reading." />
                        <h4 data-tooltip-id="currentPower"><span className="hideInMobile">Current </span>Power <InfoIcon /></h4>
                        <p className={isRefetching ? "refetching" : null}>{isOnline() ?
                            `${((data.proofOfSource?.latest?.power ?? 0) / 1000).toFixed(2)} kW` :
                            data ? "-" : <Skeleton style={skeletonMargin("0px")} inline={true} width={"75px"} />
                        }
                        </p>
                    </div>
                    <div>
                        <Tooltip id="today" place={"top-start"} noArrow content={`The amount of kWh this gateway has produced since 00:00 in the ${gatewayRequest?.data?.timeZone} timezone.`} />
                        <h4 data-tooltip-id="today">Today <InfoIcon /></h4>
                        <p>{data?.proofOfSource ? `${data.proofOfSource.today} kWh` : <Skeleton style={skeletonMargin("0px")} inline={true} width={"75px"} />}</p>
                    </div>
                </div>

                {data?.proofOfSource ?
                    <>
                        <div css={ScopesButtonGroupStyle}>
                            {scopes.map(s => <button key={s.key} className={activeScope === s.key ? "active" : null} onClick={() => setActiveScope(s.key)}>{s.displayName}</button>)}
                        </div>
                        <ProductionGraph data={data?.proofOfSource.dailykWh ? data.proofOfSource.dailykWh : data.proofOfSource.histogram}
                            type={selectedScope.chartType}
                            tooltipFormat={selectedScope.tooltipFormat}
                            label={selectedScope.label}
                            startTime={selectedScope.startDateFn()}
                        />
                    </>
                    :
                    <Skeleton height={"167px"} style={skeletonMargin("25px")} />
                }

                <div css={[ContentFooterLinks, css`margin-top: 10px;`]}>
                    <span>Updated: {data && data.proofOfSource ? data.proofOfSource.latest ? new Date(data.proofOfSource.latest.when).toLocaleString("sv") : "-" : <Skeleton style={skeletonMargin("0px")} width={"146px"} />}</span>
                    <span>
                        {!data && <Skeleton style={skeletonMargin("0px")} width={"146px"} />}
                        {isOnline() ? <span className="online">online</span> : data ? <span className="offline">offline</span> : null}
                     </span>
                </div>
            </div>
            <div css={[InfoSectionStyle]}>
                <h3>Beta rewards</h3>
                <div css={ProductionBoxStyles}>
                    <div>
                        <Tooltip id="beta" place={"top-start"} noArrow 
                            content={<span>While srcful is in beta we hand out beta rewards to gateways that participate. 
                                <br/>You can read more in the Docs about the beta rewards.</span>} 
                        />
                        <h4 data-tooltip-id="beta">Rewards <InfoIcon /></h4>
                        {gatewayRequest.data && <p>{currencyFormater.format(gatewayRequest.data.rewards?.betaPeriod?.reduce((acc,val) => acc + val.src,0) ?? 0)}</p>}
                    </div>
                    <div>
                        <Tooltip id="online" place={"top-start"} noArrow 
                            content={<span>To be rewarded in the beta-period a gateway must be online atleast 7 days in a 30 day period.<br/></span>}
                        />
                        <h4 data-tooltip-id="online" >Online days <InfoIcon /></h4>
                        {gatewayRequest.data && <p>{gatewayRequest.data.rewards?.betaPeriod?.find(x => x.src === 0).daysOnline ?? "0"}/30 days</p>}
                    </div>
                </div>
            </div>
            <div css={[InfoSectionStyle]}>
                    <h3>Gateway</h3>
                    <div css={ProductionBoxStyles}>
                        <div>
                            <h4>Make</h4>
                            <p className="small">{gatewayRequest.data ? gatewayRequest.data.inverterData?.selectedMake ?? "-" : <Skeleton style={skeletonMargin("0px")} width={"75px"} />}</p>
                        </div>
                        <div>
                            <h4>Model</h4>
                            <p className="small">{gatewayRequest.data ? gatewayRequest.data.inverterData?.model ?? "-" : <Skeleton style={skeletonMargin("0px")} width={"75px"} />}</p>
                        </div>
                        <div>
                            <h4>Rated Power</h4>
                            <p className="small">{gatewayRequest.data ? (gatewayRequest.data.inverterData?.ratedPower??'-')+" kW" ?? "-" : <Skeleton style={skeletonMargin("0px")} width={"75px"} />}</p>
                        </div>
                        <div>
                            <h4>Time zone</h4>
                            <p className="small">{gatewayRequest.data?.timeZone ?? <Skeleton style={skeletonMargin("0px")} width={"75px"} />}</p>
                        </div>
                    </div>
            </div>

    </div>
    </SkeletonTheme>

}




const InfoIcon = () => {

    return <svg viewBox="0 0 416.979 416.979">
        <path xmlns="http://www.w3.org/2000/svg" d="M356.004,61.156c-81.37-81.47-213.377-81.551-294.848-0.182c-81.47,81.371-81.552,213.379-0.181,294.85   c81.369,81.47,213.378,81.551,294.849,0.181C437.293,274.636,437.375,142.626,356.004,61.156z M237.6,340.786   c0,3.217-2.607,5.822-5.822,5.822h-46.576c-3.215,0-5.822-2.605-5.822-5.822V167.885c0-3.217,2.607-5.822,5.822-5.822h46.576   c3.215,0,5.822,2.604,5.822,5.822V340.786z M208.49,137.901c-18.618,0-33.766-15.146-33.766-33.765   c0-18.617,15.147-33.766,33.766-33.766c18.619,0,33.766,15.148,33.766,33.766C242.256,122.755,227.107,137.901,208.49,137.901z"/>
    </svg>    

}