/** @jsxImportSource @emotion/react */
import { Header } from './Header/Header';
import { Global } from '@emotion/react';
import { InfoBar } from './Infobar/Infobar';
import { ResetStyle } from './Global.css';
import { Map } from './Map/Map';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect } from 'react';
import ExplorerContextProvider, { ExplorerContext } from './ExplorerContext';
import { DataBar } from './DataBar/DataBar';
import {AppStyle, mainAreaContentStyle} from './App.css'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Footer } from './Footer/Footer';

const queryClient = new QueryClient()

function App() {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyA7Kq3Wdx8XSr_RqxqelJsygwo_xI9H6fY",
      authDomain: "srcful-explorer.firebaseapp.com",
      projectId: "srcful-explorer",
      storageBucket: "srcful-explorer.appspot.com",
      messagingSenderId: "491578493148",
      appId: "1:491578493148:web:637bd2a07e0d5fdebd4c19",
      measurementId: "G-KBT2K69PV7"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig)
    getAnalytics(app)


  })

  return (
    <>
      <Global styles={[ResetStyle]} />
      <div css={AppStyle}>
        <QueryClientProvider client={queryClient}>
          <ExplorerContextProvider>
            <ExplorerContext.Consumer>
              {() => {
                return <>
                  <Header />
                  <div css={mainAreaContentStyle}>
                    <InfoBar />
                    <DataBar />
                  </div>
                  <Footer />
                  <Map />
                </>
              }}
            </ExplorerContext.Consumer>
          </ExplorerContextProvider>
        </QueryClientProvider>
      </div>
    </>
  );
}

export default App;
