/** @jsxImportSource @emotion/react */
import { useState } from "react"
import { useContext } from "react"
import { useEffect } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Link } from "react-router-dom"
import { InfoSectionHeadingStyle, InfoSectionListLoadMoreButton, InfoSectionListStyle, InfoSectionStyle } from "../BarStyle.css"
import { ExplorerContext } from "../ExplorerContext"
import { UilClockEight, UilExchange } from '@iconscout/react-unicons'
import { BlockInformationStyle } from "./BlocksContent.css"
import { useRef } from "react"
import { useCallback } from "react"
import { format } from "date-fns"


const BlocksContent = () => {
    const { loadBlocks } = useContext(ExplorerContext)
    const [ blocks, setBlocks ] = useState([])
    const [ isLoading, setIsLoading ]= useState(true)
    const lastRef = useRef(null);
    useEffect(() => {
        if(blocks.length > 0) return
        loadBlocks().then(data => {
            setBlocks(data.data.blocks.list)
            setIsLoading(false)
        })
    }, [blocks,loadBlocks])

    var loadMore = useCallback(() => {
        setIsLoading(true)
        const lastBlockId = blocks.at(-1).block
        loadBlocks(lastBlockId).then(data => {
            setBlocks([...blocks, ...data.data.blocks.list])
            setIsLoading(false)
        });
    },[blocks,setBlocks,setIsLoading,loadBlocks])
    



    return <div>
        <div css={[InfoSectionStyle, InfoSectionHeadingStyle]}>                
            <h2 onClick={() => { setIsLoading(!isLoading) }}>Block explorer</h2>
        </div>
        <div>
            <ul css={InfoSectionListStyle}>
            { blocks.length > 0 &&
                blocks.map((b, i) => {
                    return <li key={b.block}>
                        <Link to={`/blocks/${b.block}`} ref={(i + 1) === blocks.length ? lastRef : null}>
                            <h2>#{parseInt(b.block).toLocaleString()}</h2>
                            <span css={BlockInformationStyle}>
                                <span><UilClockEight />{format(new Date(b.endTimestamp), "yyyy-MM-dd HH:mm")}</span>
                                <span><UilExchange /> {b.transactions?.length} Transactions</span>
                            </span>
                        </Link>
                    </li>
                })
            }
            </ul>
            { isLoading && <SkeletonTheme baseColor="#374051" highlightColor="rgba(106, 134, 186,0.2)"> <Skeleton height={68} count={blocks.length === 0 ? 20 : 1} /> </SkeletonTheme>}
            { !isLoading && <button css={InfoSectionListLoadMoreButton} onClick={loadMore}>Load more</button> }
        </div>
    </div>

}


export default BlocksContent